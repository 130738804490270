/* The side navigation menu */
.sidebar {
    margin: 0;
    padding: 0;
    width: 250px;
    border-radius: 10px;
    border: 1px solid var(--neutral-200, #e2e8f0);
    background: var(--f-blue, #0e237d);
    position: fixed;
    height: 100%;
    overflow: auto;
}

.sidebar a {
    display: block;
    color: #e2e8f0;
    padding: 12px;
    text-decoration: none;
}

.sidebar a.active {
    color: white;
    border-left: 3px solid #20bf6b;
}

.sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
}

.logoContainer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerLink {
    display: flex;
    align-items: center;
    color: var(--white, #fff);
    gap: 5px;
    font-size: 16px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdownToggle {
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    color: var(--white, #fff);

    font-size: 16px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdownMenu {
    // position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    background-color: transparent;
    color: #fff;
    list-style: none;
    padding-left: 20px;
}

.link {
    color: #747272 !important;
    text-decoration: none;

    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;

    &:hover {
        color: #fff !important;
    }
}

.currentLink {
    color: #fff !important;
    text-decoration: none;

    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdownItem {
    cursor: pointer;
}

.dropdownItem:hover {
    color: #fff;
}

.perxButtonContainer {
    display: none;
    margin-top: 12px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media (max-width: 888px) {
    .perxButtonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }

    .perxButton {
        border-radius: 8px;
        background: #4fc143;
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
        width: 150px;

        justify-content: center;
        height: 40px;
        color: #fff;
        gap: 7px;
    }
}
