.App {
  background-color: #ffffff;
}

p,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
tr,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0rem !important;
}

label {
  font-family: 'Outfit';
  font-style: normal;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 5px;
}
::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #919ba4;
}

input {
  height: 47px;
  background: #ffffff;
  border: 2px solid #ced4df;
  outline-color: rgba(14, 35, 125, 1);
  padding: 10px;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  height: 50px;
  border-bottom: 1px solid var(--neutral-300, #cbd5e1);
}

thead {
  border-bottom: 1px solid var(--neutral-300, #cbd5e1);
  background: var(--neutral-50, #f8fafc);
}

/* th {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--shade-black, #000);
  font-family: 'Outfit';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
} */

/* th {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--shade-black, #000);
  font-family: 'Outfit';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
} */

/* thead {
  border-bottom: 1px solid var(--neutral-300, #cbd5e1);
  background: var(--neutral-50, #f8fafc);

}

tr {

  height: 50px;


 
}

td {
  text-align: start;
} */

/* 



td {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */
