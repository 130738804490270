.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.modalContent {
    background-color: white;
    width: 70%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.textContainer > h2 {
    color: var(--neutral-900, #0f172a);
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
    margin-bottom: 0rem;
}

.loaderContainer {
    display: flex;
    justify-content: center;
}

.textContainer > p {
    color: #747272;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdownOutterCalendar {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.paginationContainer {
    display: flex;
    align-items: flex-end;
    height: 50px;
    margin-top: 10px;
}

.dropdownCalendar {
    display: flex;
    gap: 10px;
}

.tableContainer {
    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            th {
                text-align: left;
                border-bottom: 1px solid var(--neutral-300, #cbd5e1);
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid var(--neutral-300, #cbd5e1);

                td {
                    text-align: left;
                }
            }
        }
    }
}
.tableContainer {
    margin-top: 20px;
}

.outteBalanceContainer {
    width: 100%;
}

.balanceContainer {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
}

.container1,
.container2,
.container3,
.container4 {
    width: 23%;
    padding: 20px;
    height: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container1 {
    background-color: #0e237d;
}

.container2 {
    background-color: #0d99ff;
}

.container3 {
    background-color: #7b61ff;
}

.container4 {
    background-color: #fcb017;
}

.container4 > p {
    color: var(--white, #fff);
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
}

.container4 > h6 {
    color: #fff;
    font-family: "Outfit";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 28.125px */
}

.container1 > p {
    color: var(--white, #fff);
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
}

.container1 > h6 {
    color: #fff;
    font-family: "Outfit";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 28.125px */
}

.container2 > p {
    color: var(--white, #fff);
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
}

.container2 > h6 {
    color: #fff;
    font-family: "Outfit";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 28.125px */
}

.container3 > p {
    color: var(--white, #fff);
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
}

.container3 > h6 {
    color: #fff;
    font-family: "Outfit";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 28.125px */
}

@media (max-width: 1153px) {
    .outteBalanceContainer {
        overflow-x: scroll;
    }
    .balanceContainer {
        width: 830px;
    }

    .container1,
    .container2,
    .container3,
    .container4 {
        width: 200px;
        height: 80px;
    }
}

@media (max-width: 999px) {
    .tableContainer {
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .mainTable {
        width: 1000px;
        height: 150px;
    }
}

@media (max-width: 724px) {
    .dropdownOutterCalendar {
        flex-direction: column-reverse;
        gap: 30px;
    }
}

@media (max-width: 500px) {
    .dropdownOutterCalendar {
        align-items: center;
    }
    .dropdownCalendar {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .modalContent {
        width: 95%;
    }
}
