.loginContainer {
    height: 100vh;
    display: flex;
}

.loginRHS {
    max-width: 900px;
    width: 35%;
    height: 100%;
    background-image: url("../assets/images/loginLeftImage.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.loginRHS > img {
    width: 100%;
}

.loginLHS {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
}

.logoContainer {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
}

.inputContainer {
    height: calc(100% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.innerInputContainer {
    max-width: 600px;
    width: 400px;
    padding: 20px;
}

.headerText {
    color: #0f172a;
    font-family: "Outfit";
    font-size: 48px;
    font-weight: 800;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: center;
}

.paragraphText {
    text-align: center;
    font-family: "Outfit";
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;

    color: rgba(100, 116, 139, 1);
}

.innerFieldContainer {
    display: flex;
    flex-direction: column;
}

.innerFieldContainer1 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.fieldContainer {
    margin-top: 30px;
}

.errorMessage {
    color: rgba(185, 28, 28, 1);
    font-family: "Outfit";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    margin-top: 3px;
}

.inputError {
    height: 47px;
    background: #ffffff;
    border: 2px solid #ced4df;
    border-color: rgba(185, 28, 28, 1) !important;
    padding: 10px;
    border-radius: 5px;
}

.forgotPasswordLink {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.forgotPasswordText {
    text-align: right;
    margin-top: 15px;
    text-decoration: none;

    font-family: "Outfit";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: rgba(51, 65, 85, 1);
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loginBTN {
    height: 47px;
    background: rgba(14, 35, 125, 1);

    font-family: "Outfit";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.perxBTN {
    height: 47px;
    background: rgba(255, 255, 255, 1);

    font-family: "Outfit";

    line-height: 20px;
    letter-spacing: 0em;
    color: rgba(0, 0, 0, 1);
    outline: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    border: 1px solid rgba(232, 232, 232, 1);
}

.icon {
    cursor: pointer;
}

// .passwordInput {
//     margin-bottom: 13px;
// }

.iconContainer {
    float: right;
    margin-left: 320px;
    margin-top: -35px;
    position: relative;
    z-index: 2;
    height: 37px;
}
@media (max-width: 886px) {
    .loginRHS {
        width: 300px;
    }
}
@media (max-width: 481px) {
    .loginRHS {
        display: none;
    }
    .logoContainer {
        justify-content: center;
        padding-right: 0px;
    }

    .iconContainer {
        margin-left: 85%;
    }

    .innerInputContainer {
        width: 90%;
    }

    .inputContainer {
        padding-bottom: 0px;
    }
}
