.footer {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--shade-gray-2, #ced4df);
    margin-top: 30px;
}

.footerText {
    color: #747272;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 505px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }
}
