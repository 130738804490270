.tableSection {
    min-height: 90vh;
}
.topCustomer {
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 10px;
}

.reportTop > h4 {
    color: var(--neutral-900, #0f172a);
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
}

.reportTop > p {
    color: #747272;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdownCalendar {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
    z-index: 10;
}

.dropdown__toggle {
    padding: 5px;
    background-color: #fff;
    border: none;
    height: 40px;
    cursor: pointer;
    width: 150px;
    // position: relative;
    // z-index: 15;
    border: 1px solid #ccc;
}

.dropdown__menu {
    position: absolute;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    // z-index: 15;
    height: 150px;
}

.dropdown__item {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown__item:hover {
    background-color: #f2f2f2;
}

.dropdownOutterCalendar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
}

.tableContainer {
    margin-top: 30px;
}

.paginationContainer {
    display: flex;
    align-items: flex-end;
    height: 60px;
    margin-top: 20px;
}

.loaderContainer {
    display: flex;
    justify-content: center;
}

.wordContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

// .loyaltyNumber {
//     color: var(--blue, #2685fa);
//     font-family: "Outfit";
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
// }

.dateCalendar {
    display: flex;
    flex-direction: column;
}

.dateInput {
    height: 40px;
    width: 150px;
}

.tableContainer {
    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            th {
                text-align: left;
                border-bottom: 1px solid var(--neutral-300, #cbd5e1);
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid var(--neutral-300, #cbd5e1);

                td {
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 376px) {
    .dropdown__menu {
        position: unset;
        overflow-y: scroll;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 5px;
        padding: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        list-style: none;

        height: 150px;
    }
}
@media (max-width: 685px) {
    .dropdownCalendar {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 525px) {
    .reportTop {
        text-align: center;
    }
    .dropdownOutterCalendar {
        justify-content: unset;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .topCustomer {
        margin-bottom: 100px;
    }
}

@media (max-width: 1000px) {
    .tableContainer {
        overflow-x: scroll;
    }

    .mainTable {
        width: 1100px !important;
    }
}
