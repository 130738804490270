div.content {
    margin-left: 250px;
    padding: 1px 16px;
}

.mobileNavStyling {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    z-index: 12;
    &.show {
        transform: translateX(0);
        pointer-events: auto;
    }
}

@media screen and (max-width: 888px) {
    div.content {
        width: 100%;
        margin-left: 0px;
    }

    .timesIcon {
        display: flex;
        z-index: 10;
        width: 100%;
        justify-content: flex-end;
        padding-right: 18px;
        margin-top: 30px;
    }

    .bigScreenNav {
        display: none;
    }
}
